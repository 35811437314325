export const ar = {
// +++++++++++++++++
  //      SIDEBAR
  // +++++++++++++++++
  "Home": "الرئيسية",
  "Orders": "الطلبات",
  "Users": "المستخدمون",
  "User requests": "طلبات المستخدمين",
  "Banner": "لافتة إعلانية",
  "Notification": "الإشعارات",
  "Staff": "الموظفون",
  "Setting": "الإعدادات",
  "Logout": "تسجيل الخروج",

  // +++++++++++++++++
  //    DASHBOARD
  // +++++++++++++++++
  "Dashboard": "لوحة التحكم",
  "Merchant": "التاجر",
  "Delivery": "التوصيل",
  "Income": "الدخل",
  "Delivery Price": "سعر التوصيل",
  "App comission": "عمولة التطبيق",
  "Total Price": "السعر الإجمالي",
  "User type": "نوع المستخدم",
  "View rider": "عرض السائق",

  // +++++++++++++++++
  //    ORDERS
  // +++++++++++++++++
  "Returned Orders": "الطلبات المرجعة",
  "Order Images": "صور الطلب",
  "Order status": "حالة الطلب",
  "Completed Orders": "الطلبات المكتملة",
  "Underway Orders": "الطلبات الجارية",
  "Delivered Order": "الطلبات المسلمة",
  "Order ID": "رقم الطلب",
  "Order Type": "نوع الطلب",
  "Order details": "تفاصيل الطلب",
  "Date Order": "تاريخ الطلب",
  "Underway Order": "طلب قيد التنفيذ",
  "Complete Order": "طلب مكتمل",
  "Returned Order": "طلب مرجع",

  // ++++++++++++++++++++
  //       USERS
  // ++++++++++++++++++++
  "Delivery Boys": "مندوبو التوصيل",
  "Merchants": "التجار",
  "Merchant Name": "اسم التاجر",
  "Customer  Name": "اسم العميل",
  "Delivery Boy": "مندوب التوصيل",
  "User name": "اسم المستخدم",

  // +++++++++++++++++++++++
  //       USER REQUESTS
  // +++++++++++++++++++++++
  "Rejected Reason": "سبب الرفض",
  "Enter rejected reason": "أدخل سبب الرفض",

  // +++++++++++++++
  //     BANNER
  // +++++++++++++++
  "Enter post url": "أدخل رابط المنشور",
  "Edit Banner": "تعديل اللافتة",
  "Add Banner": "إضافة لافتة",
  "url": "الرابط",
  "Start date": "تاريخ البدء",
  "End date": "تاريخ الانتهاء",

  // +++++++++++++++++++
  //   NOTIFICATION
  // +++++++++++++++++++
  "Send Notification": "إرسال إشعار",
  "User phone": "رقم هاتف المستخدم",
  "Title": "العنوان",
  "Description": "الوصف",
  "Enter the Description here": "أدخل الوصف هنا",

  // +++++++++++++++++++++
  //        STAFF
  // +++++++++++++++++++++
  "Add Staff": "إضافة موظف",
  "Type": "النوع",

  // +++++++++++++++++++++++++
  //          SETTINGS
  // +++++++++++++++++++++++++++
  "Terms & Privacy Settings": "إعدادات الشروط والخصوصية",
  "Company Settings": "إعدادات الشركة",
  "Company Title": "اسم الشركة",
  "Support Name": "اسم الدعم",
  "Mobile": "رقم الهاتف",
  "Country Code": "رمز الدولة",
  "Support Email": "البريد الإلكتروني للدعم",
  "Company Whatsapp": "واتساب الشركة",
  "Company X": "حساب الشركة في X",
  "Company Instagram": "إنستغرام الشركة",
  "Referral_bonus": "مكافأة الإحالة",
  "Login Image": "صورة تسجيل الدخول",

  // +++++++++++++++++++++++
  //       SERVICES
  // +++++++++++++++++++++++
  "Price Detail": "تفاصيل السعر",

  // ++++++++++++++++++++++++++++
  //       LOCATION
  // ++++++++++++++++++++++++++++
  "Customer Location": "موقع العميل",
  "Merchant location": "موقع التاجر",

  // ++++++++++++++++++++++++++++
  //       INFORMATION
  // ++++++++++++++++++++++++++++
  "Rider Information": "معلومات السائق",

  // +++++++++++++++++
  //      GLOBAL
  // +++++++++++++++++
  "Amount added": "المبلغ المضاف",
  "Enter Title": "أدخل العنوان",
  "Enter amount": "أدخل المبلغ",
  "Are you sure you want to delete the record ?": "هل أنت متأكد أنك تريد حذف السجل؟",
  "Cancel": "إلغاء",
  "OK": "موافق",
  "Edit": "تعديل",
  "Yes": "نعم",
  "Amount": "المبلغ",
  "Referral Code": "رمز الإحالة",
  "Completed Orders": "الطلبات المكتملة",
  "Add Balance": "إضافة رصيد",
  "Reject": "رفض",
  "Accept": "قبول",
  "Note": "ملاحظة",
  "Username": "اسم المستخدم",
  "Time": "الوقت",
  "View": "عرض",
  "Date": "التاريخ",
  "From": "من",
  "To": "إلى",
  "Status": "الحالة",
  "Action": "إجراء",
  "Image": "صورة",
  "Name": "الاسم",
  "Email": "البريد الإلكتروني",
  "Id": "المعرف",
  "Phone": "الهاتف",
  "Add": "إضافة",
  "Delete": "حذف",
  "Created at": "تم الإنشاء في",
  "Save": "حفظ",
  "Password": "كلمة المرور",
  "No": "لا",
  "Post": "منشور",
  "Latest Requests": "أحدث الطلبات",
  "Latest Balance Requests": "أحدث طلبات الرصيد",
  "OMR": "ريال عماني",

  "Balance requests": "طلبات الرصيد",
  "Merchant info": "معلومات التاجر",
  "Rider Info": "معلومات السائق",
  "Riders": "السائقون",
  "Ratings": "التقييمات",
  "Ratings & Reviews": "التقييمات والمراجعات",
  "Rider Information": "معلومات السائق",
  "Username": "اسم المستخدم",
  "Phone": "الهاتف",
  "Promo code": "رمز الترويج",
  "Finding Riders": "البحث عن سائقين",
  "Customer Name": "اسم العميل",
  "Merchant map location": "موقع التاجر على الخريطة",
  "Customer map location": "موقع العميل على الخريطة",
  "Merchant Details": "تفاصيل التاجر",
  "Rider Details": "تفاصيل السائق",

  "WhatsApp": "واتساب",
  "Trade Type": "نوع التجارة",
  "Company Name": "اسم الشركة",
  "Company Place": "مكان الشركة",
  "CR Number": "رقم السجل التجاري",
  "Company CR": "السجل التجاري للشركة",
  "View File": "عرض الملف",
  "Email": "البريد الإلكتروني",
  "ID Card Number": "رقم البطاقة الشخصية",
  "ID Front": "الوجه الأمامي للبطاقة",
  "N/A": "غير متاح",
  "ID Back": "الوجه الخلفي للبطاقة",
  "Processing": "قيد المعالجة",
  "Approve": "موافقة",
  "Cancelled Orders": "الطلبات الملغاة",
  "Rejected at": "مرفوض في",
  "Rejected date": "تاريخ الرفض",
  "Merchant Name": "اسم التاجر",
  "Promo person": "الشخص الترويجي",
  "Referred By": "تمت الإحالة بواسطة",
  "Merchant Information": "معلومات التاجر",
  "Merchant type": "نوع التاجر",
  "Download Company CR": "تحميل السجل التجاري",
  "Your browser does not support the video tag.": "المتصفح الخاص بك لا يدعم علامة الفيديو.",
  "Update": "تحديث",
  "No ratings available": "لا توجد تقييمات متاحة",
};
