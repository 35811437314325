export const en = {
  // +++++++++++++++++
  //      SIDEBAR
  // +++++++++++++++++
  "Home": "Home",
  "Orders": "Orders",
  "Users": "Users",
  "User requests": "User requests",
  "Banner": "Banner",
  "Notification": "Notification",
  "Staff": "Staff",
  "Setting": "Setting",
  // TODO: logout word added
  "Logout": "Logout",

  // +++++++++++++++++
  //    DASHBOARD
  // +++++++++++++++++
  "Dashboard": "Dashboard",
  "Merchant": "Merchant",
  "Delivery": "Delivery",
  "Income": "Income",
  "Delivery Price": "Delivery Price",
  "App comission": "App comission",
  "Total Price": "Total Price",
  "User type": "User type",
  "View rider": "View rider",


  // +++++++++++++++++
  //    ORDERS
  // +++++++++++++++++
  "Returned Orders": "Returned Orders",
  "Order Images": "Order Images",
  "Order status": "Order status",
  "Completed Orders": "Completed Orders",
  "Underway Orders": "Underway Orders",
  "Delivered Order": "Delivered Orders",
  "Order ID": "Order ID",
  "Order Type": "Order Type",
  "Order details": "Order details",
  "Date Order": "Date Order",
  "Underway Order": "Underway Order",
  "Complete Order": "Complete Order",
  "Returned Order": "Returned Order",

  // ++++++++++++++++++++
  //       USERS
  // ++++++++++++++++++++
  "Delivery Boys": "Delivery Boys",
  "Merchants": "Merchants",
  "Merchant Name": "Merchant Name",
  "Customer  Name": "Customer  Name",
  "Delivery Boy": "Delivery Boy",
  "User name": "User name",

  // +++++++++++++++++++++++
  //       USER REQUESTS
  // +++++++++++++++++++++++
  "Rejected Reason": "Rejected Reason",
  "Enter rejected reason": "Enter rejected reason",

  // +++++++++++++++
  //     BANNER
  // +++++++++++++++
  "Enter post url": "Enter post url",
  "Edit Banner": "Edit Banner",
  "Add Banner": "Add Banner",
  "url": "url",
  "Start date": "Start date",
  "End date": "End date",

  // +++++++++++++++++++
  //   NOTIFICATION
  // +++++++++++++++++++
  "Send Notification": "Send Notification",
  "User phone": "User phone",
  "Title": "Title",
  "Description": "Description",
  "Enter the Description here": "Enter the Description here",

  // +++++++++++++++++++++
  //        STAFF
  // +++++++++++++++++++++
  "Add Staff": "Add Staff",
  "Type": "Type",

  // +++++++++++++++++++++++++
  //          SETTINGS
  // +++++++++++++++++++++++++++
  "Terms & Privacy Settings": "Terms & Privacy Settings",
  "Company Settings": "Company Settings",
  // TODO: Setting word being used from sidebar section
  "Company Title": "Company Title",
  "Support Name": "Support Name",
  "Mobile": "Mobile",
  "Country Code": "Country Code",
  "Support Email": "Support Email",
  "Company Whatsapp": "Company Whatsapp",
  "Company X": "Company X",
  "Company Instagram": "Company Instagram",
  // TODO: referral bonus created
  "Referral_bonus": "Referral bonus",
  "Login Image": "Login Image",

  // +++++++++++++++++++++++
  //       SERVICES
  // +++++++++++++++++++++++
  "Price Detail": "Price Detail",

  // ++++++++++++++++++++++++++++
  //       LOCATION
  // ++++++++++++++++++++++++++++
  "Customer Location": "Customer Location",
  "Merchant location": "Merchant location",

  // ++++++++++++++++++++++++++++
  //       INFORMATION
  // ++++++++++++++++++++++++++++
  "Rider Information": "Rider Information",

  // +++++++++++++++++
  //      GLOBAL
  // +++++++++++++++++
  "Amount added": "Amount added",
  "Enter Title": "Enter Title",
  "Enter amount": "Enter amount",
  "Are you sure you want to delete the record ?": "Are you sure you want to delete the record ?",
  "Cancel": "Cancel",
  "OK": "OK",
  "Edit": "Edit",
  "Yes": "Yes",
  "Amount": "Amount",
  "Referral Code": "Referral Code",
  "Completed Orders": "Completed Orders",
  "Add Balance": "Add Balance",
  "Reject": "Reject",
  "Accept": "Accept",
  "Note": "Note",
  "Username": "Username",
  "Time": "Time",
  "View": "View",
  "Date": "Date",
  "From": "From",
  "To": "To",
  "Status": "Status",
  "Action": "Action",
  "Image": "Image",
  "Name": "Name",
  "Email": "Email",
  "Id": "Id",
  "Phone": "Phone",
  "Add": "Add",
  "Delete": "Delete",
  "Created at": "Created at",
  "Save": "Save",
  "Password": "Password",
  "No": "No",
  "Post": "Post",
  "Latest Requests": "Latest Requests",
  "Latest Balance Requests": "Latest Balance Requests",
  "OMR": "OMR",

  "Balance requests": "Balance requests",
  "Merchant info": "Merchant info",
  "Rider Info": "Rider Info",
  "Riders": "Riders",
  "Ratings": "Ratings",
  "Ratings & Reviews": "Ratings & Reviews",
  "Rider Information": "Rider Information",
  "Username": "Username",
  "Phone": "Phone",
  "Promo code": "Promo code",
  "Finding Riders": "Finding Riders",
  "Customer Name": "Customer Name",
  "Merchant map location": "Merchant map location",
  "Customer map location": "Customer map location",
  "Merchant Details": "Merchant Details",
  "Rider Details": "Rider Details",


  "WhatsApp": "WhatsApp",
  "Trade Type": "Trade Type",
  "Company Name": "Company Name",
  "Company Place": "Company Place",
  "CR Number": "CR Number",
  "Company CR": "Company CR",
  "View File": "View File",
  "Email": "Email",
  "ID Card Number": "ID Card Number",
  "ID Front": "ID Front",
  "N/A": "N/A",
  "ID Back": "ID Back",
  "Processing": "Processing",
  "Approve": "Approve",
  "Cancelled Orders": "Cancelled Orders",
  "Rejected at": "Rejected at",
  "Rejected date": "Rejected date",
  "Merchant Name": "Merchant Name",
  "Promo person": "Promo person",
  "Referred By": 'Referred By',
  "Merchant Information": "Merchant Information",
  "Merchant type": "Merchant type",
  "Download Company CR" : "Download Company CR",
  "Your browser does not support the video tag.": "Your browser does not support the video tag.",
  "Update" : "Update",
  "No ratings available" : "No ratings available",
};