import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { Dropdown, Menu, Button, } from "antd";

import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import {
  DotMenuIcon,
  NotesIcon,
  IncomeIcon,
  UsersIcon,
  ChartIcon,
  TrashIcon,
  EyeIcon2,
} from "../../assets/Icons";
import NewsDetailsModel from "../../components/NewsDetailsModel";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import {
  useApproveAdMutation,
  useGetAdsQuery,
  useRejectAdMutation,
} from "../../store/services/adsService";
import DataTable from "../../components/DataTable";
import { useSelector } from "react-redux";
import { Image } from "antd";
import { useGetOrdersQuery } from "../../store/services/orderService";
import { Box, Typography } from "@mui/material";
import RejectedReasonModel from "../../components/RejectedReasonModel";

const Main = () => {
  // const words = useSelector((state)=>state.au)


  const words = useSelector((state) => state.authReducer.words);
  const isAdmin = JSON.parse(localStorage.getItem("admin"));
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState({
    users: "",
    posts: "",
    ad: "",
    income: "",
  });
  const [latestOrders, setLatestOrders] = useState([]);
  const [balanceRequests, setBalanceRequests] = useState([]);
  const [latestRequests, setLatestRequests] = useState([]); // LATEST USER REQUESTS

  const [balanceRequestId, setBalanceRequestId] = useState('');
  const [rejectedBalanceRequestModel, setRejectedBalanceRequestModel] = useState(false);


  const columns = [
    {
      title: words["Order ID"],
      dataIndex: "id",
      key: "title",
    },
    {
      title: words["Merchant Name"],
      dataIndex: "merchant_name",
      key: "merchantName",
    },
    {
      title: words["Customer  Name"],
      dataIndex: "cust_name",
      key: "cust_name",
    },

    {
      title: words["Date"],
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}
        </div>
      ),
    },
    {
      title: words["From"],
      dataIndex: "merchant_location",
      key: "merchant_location",
    },
    {
      title: words["To"],
      dataIndex: "cust_location",
      key: "cust_location",
    },

    {
      title: words["Order Type"],
      dataIndex: "order_type",
      key: "order_type",
    },

    {
      title: words["Status"],
      dataIndex: "order_status",
      key: "order_status",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {capitalizeFirstLetter(record.order_status)}
        </div>
      )

    },

    {
      title: words["Delivery Boy"],
      key: "deliveryBoy",
      render: (_, record) => (
        <>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <Image
              src={IMAGE_URL + record.rider?.image}
              style={{ width: 30, height: 30, borderRadius: 5 }}
            /> */}
            {record.rider?.username}
          </div>
        </>
      ),
    },

    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "view") {
                  navigate("/dashboard/order-details/" + record.id);
                  // alert('asd');
                  return;
                }

              }}
            >
              <>
                <Menu.Item key="view">{words["View"]}</Menu.Item>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          {/* <Button> */}
          <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
            <DotMenuIcon />
          </div>
          {/* </Button> */}
        </Dropdown>
      ),
    },
  ];


  const userRequestcolumns = [
    {
      title: words["Id"],
      dataIndex: "id",
      key: "title",
    },
    {
      title: words["User type"],
      dataIndex: "user_type",
      key: "user_type",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {capitalizeFirstLetter(record.user_type)}
        </div>
      )
    },
    {
      title: words["Name"],
      dataIndex: "name",
      key: "name",
    },

    {
      title: words["Date"],
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}
        </div>
      ),
    },

  ];


  const balanceRequestsColumns = [
    {
      title: words["Id"],
      dataIndex: "id",
      key: "title",
    },
    {
      title: words["Amount"],
      dataIndex: "amount_requested",
      key: "amount_requested",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {words["OMR"]} {record.amount_requested}
        </div>
      )
    },
    {
      title: words["Name"],
      dataIndex: "ridername",
      key: "rider.name",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {capitalizeFirstLetter(record.rider?.username)}
        </div>
        // user-details/7
      )
    },


    {
      title: words["Date"],
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}
        </div>
      ),
    },



    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "view") {
                  navigate("/dashboard/user-details/" + record.rider.id);
                  return;
                }

                setBalanceRequestId(record.id);
                if (key == 'add') {
                  return handleBalanceRequest('added', null, record.id)
                }
                if (key == 'reject') {
                  setRejectedBalanceRequestModel(true);
                  return;
                }
              }}
            >
              <>
                <Menu.Item key="add">{words["Add"]}</Menu.Item>
                <Menu.Item key="reject">{words["Reject"]}</Menu.Item>
                <Menu.Item key="view">{words["View rider"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
            <DotMenuIcon />
          </div>
        </Dropdown>
      ),
    },
  ];





  const getDashboard = async () => {
    const onSuccess = (res) => {
      setLoading(false);
      setCounts({
        ...counts,
        users: res?.data.users_count,
        posts: res?.data.posts_count,
        ad: res?.data.ad_count,
        income: res?.data.income,
      });
      setBalanceRequests(res.data?.latest_balance_requests);
      setLatestRequests(res.data?.latest_requests);
      setLatestOrders(res.data.latest_orders);
    };

    const onError = (err) => {
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    await postRequest("", routes.dashboard, true, onSuccess, onError);
  };

  const handleBalanceRequest = async (status, reason, id) => {

    setRejectedBalanceRequestModel(false);
    const onSuccess = () => {
      if (status == 'added') toast.success('Balance added successfully');
      if (status == 'rejected') toast.success('Balance request rejected successfully');
      getDashboard();
    }

    const onError = (res) => {
      setLoading(false);
      toast.error(res.error)
    }

    const body = {
      status,
      id: id
    }
    if (status == 'rejected') body.rejected_reason = reason;

    setLoading(true);
    await postRequest(body, 'admin/rider/balance-request', true, onSuccess, onError)
  }



  useEffect(() => {
    getDashboard();
  }, []);


  return (
    <Layout>

      <div className="wrap wrapWidth flex flex-col items-center ">
        <Loader open={loading} />
      </div>

      <div className="lading-page h-screen flex">
        <div className="wrap wrapWidth flex flex-col">
          <div className="home-grid w-full grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 mb-8">
            <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
              <div className="icon flex items-center justify-center bg-[#47A000]/20 h-16 w-16 rounded-2xl">
                <UsersIcon />
              </div>
              <div className="flex flex-col gap-2 py-2">
                <p className="text-[#696974] text-xs">{words["Merchant"]}</p>
                <h2 className="text-[#171725] text-xl font-medium">
                  {counts.users}
                </h2>
              </div>
            </div>
            <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
              <div className="icon flex items-center justify-center bg-[#0066A0]/20 h-16 w-16 rounded-2xl">
                <UsersIcon />
              </div>
              <div className="flex flex-col gap-2 py-2">
                <p className="text-[#696974] text-xs">{words["Delivery"]}</p>
                <h2 className="text-[#171725] text-xl font-medium">
                  {counts.posts}
                </h2>
              </div>
            </div>
            <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
              <div className="icon flex items-center justify-center bg-[#A0009A]/20 h-16 w-16 rounded-2xl">
                {/* <div className="text-[#A0009A]"> */}
                <ChartIcon />
                {/* </div> */}
              </div>
              <div className="flex flex-col gap-2 py-2">
                <p className="text-[#696974] text-xs">{words["Orders"]}</p>
                <h2 className="text-[#171725] text-xl font-medium">
                  {counts.ad}
                </h2>
              </div>
            </div>
            <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
              <div className="icon flex items-center justify-center bg-[#F21531]/20 h-16 w-16 rounded-2xl">
                <div className="text-[#F21531]">
                  <IncomeIcon />
                </div>
              </div>
              <div className="flex flex-col gap-2 py-2">
                <p className="text-[#696974] text-xs">{words["Income"]}</p>
                <h2 className="text-[#171725] text-xl font-medium">
                  {counts.income}
                </h2>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: "row" }} >
            <div style={{ width: "49%" }}>
              <Box display={"flex"} >
                <Box sx={{ height: 20, width: 7, borderRadius: 12, bgcolor: '#2EBFB9' }} />
                <Typography sx={{ marginLeft: 1, fontFamily: 'MSBo', fontSize: 18 }}>{words["Latest Requests"]}</Typography>
              </Box>
              <DataTable
                searchContainerStyle={{ width: "40%" }}
                data={latestRequests}
                columns={userRequestcolumns}
                isPagination={false}
                isSearch={false}
              />
            </div>
            <div style={{ width: "49%" }}>
              <Box display={"flex"} >
                <Box sx={{ height: 20, width: 7, borderRadius: 12, bgcolor: '#2EBFB9' }} />
                <Typography sx={{ marginLeft: 1, fontFamily: 'MSBo', fontSize: 18 }}>{words["Latest Balance Requests"]}</Typography>
              </Box>
              <DataTable
                data={balanceRequests}
                columns={balanceRequestsColumns}
                isPagination={false}
                isSearch={false}
              />
            </div>

          </div>

          <div>
            <DataTable
              searchContainerStyle={{ width: "40%" }}
              data={latestOrders}
              columns={columns}
            />
          </div>


          {/* <div>
              <h1 className="text-[#18120F] text-2xl font-medium border-l-8 border-themeColor px-2">
                {words["Latest posts"]}
              </h1>
              <DataTable
                searchContainerStyle={{ width: "40%" }}
                data={latestPost}
                columns={postsColumns}
              />
            </div> */}

          {/* <div className="flex flex-col gap-2 mb-8">
              <h1 className="text-[#18120F] text-2xl font-medium border-l-8 border-themeColor px-2">
                {words["Latest posts"]}
              </h1>
              <div className="responsive-table overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#F5F7FB]">
                    <tr>
                      <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                        {words["No"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Image"]}
                      </th>
                      <th className="px-2 py-2 text-left  w-56 text-sm text-black font-medium">
                        {words["Title"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Date"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Category"]}
                      </th>
                      
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Publisher"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Status"]}
                      </th>
                    
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {latestPost?.map((item, index) => (
                      <TableRow item={item} index={index} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div> */}

          {/* <div className="flex flex-col gap-2">
              <h1 className="text-[#18120F] text-2xl font-medium border-l-8 border-themeColor px-2">
                {words["Latest orders"]}
              </h1>
              <div>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={ads?.data ?? []}
                  columns={columns}
                />
              </div>
            </div> */}
        </div>
        <Modal open={openModal} setOpen={setOpenModal}>
          <NewsDetailsModel
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </Modal>

        <Modal open={rejectedBalanceRequestModel} setOpen={setRejectedBalanceRequestModel}>
          <RejectedReasonModel
            openModal={rejectedBalanceRequestModel}
            setOpenModal={setRejectedBalanceRequestModel}
            onSubmit={(reason) => handleBalanceRequest('rejected', reason, balanceRequestId)}
          />
        </Modal>


      </div>
    </Layout>
  );
};

export default Main;
