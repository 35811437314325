import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import { DotMenuIcon, TrashIcon, EyeIcon2 } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import ApprovalRequests from "../News/ApprovalRequests";
import UnderwayOrderTable from "./subtabs/underwayOrderTable";
import AdsTable from "../News/AdsTable";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import AppLoader from "../../components/Loader/AppLoader";
import { useSelector } from "react-redux";
// import InfoOrderTable from "./OrderDetails";
import CompleteOrderTable from "./subtabs/CompleteOrder";
import ReturnOrderTable from "./subtabs/ReturnOrder";
import { useGetSettingQuery } from "../../store/services/settingService";
import { useGetOrdersQuery } from "../../store/services/orderService";

const Order = () => {
  const words = useSelector((state) => state.authReducer.words);
  const isAdmin = JSON.parse(localStorage.getItem("admin"));

  const { data: orderData, isLoading, isSuccess, isError } = useGetOrdersQuery();
  const [underwayOrders, setUnderwayOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [returnedOrders, setReturnedOrders] = useState([]);
  const [findingRider, setFindingRiderOrders] = useState([]);


  const tabs = [
    {
      label: words["Underway Orders"],
      value: "underwayOrder",
    },
    {
      label: words["Completed Orders"],
      value: "completeOrder",
    },
    {
      label: words["Returned Orders"],
      value: "returnedOrder",
    },
    {
      label: words["Finding Riders"],
      value: "findingRIder",
    },
  ];

  const [approvePosts, setApprovalPosts] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {

    const orders = orderData?.data?.length ? orderData.data : [];
    const findingRider = orders.filter((v) => v.order_status == 'finding_rider');
    const underway = orders.filter((v) => v.order_status == 'underway');
    const completed = orders.filter((v) => v.order_status == 'delivered');
    const returned = orders.filter((v) => v.order_status == 'returned');
    setUnderwayOrders(underway);
    setCompletedOrders(completed);
    setReturnedOrders(returned)
    setFindingRiderOrders(findingRider);
  }, [orderData])

  return (
    <>
      {loading && <AppLoader />}

      <Layout pageTitle={words["Orders"]}>
        <div className="store-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            {/* {isAdmin?.type == "admin" ? ( */}
              <div className="flex flex-col gap-5 mt-6">
                <div className="flex w-full border-b-[1px] border-b-themeColor border-transparent rounded ">
                  {tabs.map((item, index) => (
                    <button
                      key={index}
                      className={`btn font-normal rounded ${activeTab === item.value
                        ? "bg-[#2EBFB9] text-white"
                        : "bg-transparent text-[#2EBFB9]"
                        }`}
                      onClick={(e) => setActiveTab(item.value)}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
                {activeTab === "underwayOrder" && <UnderwayOrderTable
                  data={underwayOrders}
                />}
                {activeTab === "findingRIder" && <UnderwayOrderTable
                  type="findingRider"
                  data={findingRider}
                />}
                {activeTab === "completeOrder" && (
                  <CompleteOrderTable
                    loadingState={(val) => {
                      setLoading(val);
                    }}
                    refreshData={""}
                    data={completedOrders}
                  />
                )}
                {activeTab === "returnedOrder" && <ReturnOrderTable
                  data={returnedOrders}
                />}
              </div>
            {/* ) : ( */}
              {/* <UnderwayOrderTable refreshData={""} data={""} /> */}
            {/* )} */}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Order;
