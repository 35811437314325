import React, { useEffect, useState } from "react";
import { Card, Divider, Image, Rate } from "antd"; // Removed Flex import
import { useSelector } from "react-redux";
import { Box, Container, Grid, Icon, Typography, Rating, Link } from "@mui/material"; // Use Box and Typography for layout and text styling
import { Icons } from "../../assets/svgs/svg"; // Ensure Icons are correctly imported
import SearchBar from "../../components/SearchBar";
import { Layout } from "../../components";
import { useParams } from "react-router-dom";
import { getRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { toast } from "react-toastify";
import {
  capitalizeFirstLetter,
  getDateString,
  getTimeString,
} from "../../utils/functions";
import { IMAGE_URL } from "../../Api/constants";
import { GalleryIcon } from "../../assets/Icons";
import { Mail, Phone, Star, Map } from "@mui/icons-material";

const OrderDetails = () => {
  const words = useSelector((state) => state.authReducer.words);
  const { id } = useParams();
  const [details, setDetails] = useState({});

  const getDetails = async () => {
    const onSuccess = (res) => {
      console.log("res", res);
      setDetails(res.data);
    };
    const onError = (err) => {
      toast.error(err?.error);
    };

    await getRequest({}, routes.getOrderDetails + id, true, onSuccess, onError);
  };

  useEffect(() => {
    getDetails();
  }, []);
  const labelsStyle = {
    display: "flex",
    fontFamily: "IMe",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "25.2px",
    letterSpacing: "0.01em",
    textAlign: "left",
    marginLeft: "10px",
  };

  const labelValueStyle = {
    fontFamily: "IRe",
    color: "rgb(0,0,0,0.7)",
    marginLeft: 5,
  };

  const TitlesStyle = {
    fontFamily: "IMe",
    fontSize: "23px",
    fontWeight: 600,
    lineHeight: "32.2px",
    letterSpacing: "0.01em",
    textAlign: "left",
  };

  const Seperator = () => {
    return <Divider style={{ margin: "10px 0", backgroundColor: "#8E8E8F" }} />;
  };

  return (
    <Layout pageTitle={""}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography style={TitlesStyle}>
                {words["Order details"]}
              </Typography>
              <Card
                bordered={true}
                style={{
                  gap: "0px",
                  border: "0.5px solid ",
                  opacity: 1,
                  borderRadius: "15px",
                  marginTop: "15px",
                  padding: "20px",
                  overflow: "auto",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Icons.custIcon />
                  <Typography style={labelsStyle}>
                    {words["Customer Name"] + ":"}
                    <div style={labelValueStyle}> {details.cust_name}</div>{" "}
                  </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusIconCal />
                  <Typography style={labelsStyle}>
                    {words["Date Order"] + ":"}
                    <div style={labelValueStyle}>
                      {" "}
                      {getDateString(details.created_at)}
                    </div>{" "}
                  </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusIconSq />
                  <Typography style={labelsStyle}>
                    {words["Order Type"] + ":"}
                    <div style={labelValueStyle}>
                      {" "}
                      {details.order_type}
                    </div>{" "}
                  </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusIconloc />
                  <Typography style={labelsStyle}>
                    {words["Customer Location"] + ":"}
                    <div style={labelValueStyle}>
                      {" "}
                      {details.cust_location}
                    </div>{" "}
                  </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusIconTime />
                  <Typography style={labelsStyle}>
                    {words["Time"] + ":"}{" "}
                    <div style={labelValueStyle}>
                      {" "}
                      {getTimeString(details.right_time)}{" "}
                    </div>
                  </Typography>
                </Box>
                <Seperator />
                <Box display="flex" alignItems="center">
                  <Icons.cusIconTime />
                  <Typography style={labelsStyle}>
                    {words["Order status"] + ":"}{" "}
                    <div style={labelValueStyle}>
                      {" "}
                      {capitalizeFirstLetter(details.order_status)}{" "}
                    </div>
                  </Typography>
                </Box>
                <Seperator />
                <Box display="flex" alignItems="center">
                  <Map style={{ marginRight: 5 }} />
                  <Typography style={labelsStyle}>
                    {words["Customer map location"] + ":"}{" "}
                    <div style={labelValueStyle}> {details?.order_note}</div>
                  </Typography>
                  <Link
                    href={details.customer_map_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "#007bff", fontWeight: "bold" }}
                  >
                    Open in Google Maps
                  </Link>
                </Box>


                <Seperator />
                {details.cancelled_reason && (
                  <>
                    <Box display="flex" alignItems="center">
                      <Icons.cusMerchantNotes />
                      <Typography style={{ ...labelsStyle, color: "red" }}>
                        Cancelled Reason:{" "}
                        <div style={labelValueStyle}>
                          {" "}
                          {capitalizeFirstLetter(details.cancelled_reason)}{" "}
                        </div>
                      </Typography>
                    </Box>
                    <Seperator />
                  </>
                )}
                {details.rejected_reason && (
                  <>
                    <Box display="flex" alignItems="center">
                      <Icons.cusMerchantNotes />
                      <Typography style={{ ...labelsStyle, color: "red" }}>
                        {words["Rejected Reason"] + ":"}{" "}
                        <div style={labelValueStyle}>
                          {" "}
                          {capitalizeFirstLetter(details.rejected_reason)}{" "}
                        </div>
                      </Typography>
                    </Box>
                    <Seperator />
                  </>
                )}
                <Box display="flex" alignItems="center">
                  <GalleryIcon width={30} height={30} />
                  <Typography style={labelsStyle}>
                    {words["Order Images"] + ":"}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ marginTop: 10, marginLeft: -10 }}
                >
                  {details?.order_images?.length
                    ? details?.order_images.map((v, i) => {
                      return (
                        <Image
                          style={{
                            opacity: 1,
                            borderRadius: "10px",
                            width: 100,
                            height: 100,
                            marginLeft: 10,
                          }}
                          key={v.id}
                          src={IMAGE_URL + v.image}
                        />
                      );
                    })
                    : null}
                  {/* <Card
                    bordered={true}
                    style={{
                      gap: "2px",
                      border: "0.5px solid ",
                      opacity: 1,
                      borderRadius: "15px",
                      marginTop: "15px",
                      padding: "20px",
                    }}
                  >
                    <Icons.cusImageIcon />
                  </Card>
                  <Card
                    bordered={true}
                    style={{
                      gap: "2px",
                      border: "0.5px solid ",
                      opacity: 1,
                      borderRadius: "15px",
                      marginTop: "15px",
                      padding: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <Icons.cusImageIcon />
                  </Card>
                  <Card
                    bordered={true}
                    style={{
                      gap: "2px",
                      border: "0.5px solid ",
                      opacity: 1,
                      borderRadius: "15px",
                      marginTop: "15px",
                      padding: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <Icons.cusImageIcon />
                  </Card> */}
                </Box>

                {/* <Typography style={labelsStyle}>
                  {words["Ratings"] + ":"}
                </Typography>
                <Typography sx={{ fontFamily: 'PRe', display: 'flex', alignItems: 'center', }}>
                  <strong style={{ marginRight: 2 }}>{details.rating?.text}</strong>
                  <Rating
                    value={parseFloat(details.rating?.rating)}
                    precision={0.5}
                    sx={{ fontSize: 16, marginLeft: 1 }}
                    readOnly
                  />
                </Typography> */}


              </Card>

              {details?.rider?.id && (
                <Box mt={3}>
                  <Typography style={TitlesStyle}>
                    {words["Rider Information"]}
                  </Typography>
                  <Card
                    bordered={true}
                    style={{
                      gap: "0px",
                      border: "0.5px solid ",
                      opacity: 1,
                      borderRadius: "15px",
                      marginTop: "15px",
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        src={IMAGE_URL + details.rider.image}
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: 10,
                          marginBottom: 10,
                        }}
                      />
                    </div>
                    <Box display="flex" alignItems="center">
                      <Icons.cusMerchant />
                      <Typography style={labelsStyle}>
                        {words["Rejected Reason"] + ":"}{" "}
                        <div style={labelValueStyle}>
                          {" "}
                          {details?.rider.username}
                        </div>
                      </Typography>
                    </Box>
                    <Seperator />

                    <Box display="flex" alignItems="center">
                      <Mail />
                      <Typography style={labelsStyle}>
                        {words["Email"] + ":"}{" "}
                        <div style={labelValueStyle}>
                          {" "}
                          {details?.rider.email}
                        </div>
                      </Typography>
                    </Box>
                    <Seperator />
                    <Box display="flex" alignItems="center">
                      <Phone />
                      <Typography style={labelsStyle}>
                        {words["Phone"] + ":"}{" "}
                        <div style={labelValueStyle}>
                          {" "}
                          {details?.rider.phone}
                        </div>
                      </Typography>
                    </Box>
                  </Card>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography style={TitlesStyle}>{words["Merchant"]}</Typography>
              <Card
                bordered={true}
                style={{
                  gap: "0px",
                  border: "0.5px solid ",
                  opacity: 1,
                  borderRadius: "15px",
                  marginTop: "15px",
                  padding: "20px",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Icons.cusMerchant />
                  <Typography style={labelsStyle}>
                    {words["Merchant Name"] + ":"}{" "}
                    <div style={labelValueStyle}> {details?.merchant_name}</div>
                  </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusMerchantOrder />
                  <Typography style={labelsStyle}>
                    {words["Merchant location"] + ":"}{" "}
                    <div style={labelValueStyle}>
                      {" "}
                      {details?.merchant_location}
                    </div>
                  </Typography>
                </Box>
                <Seperator />

                {/* <Box display="flex" alignItems="center">
                  <Icons.cusMerchantWhatsapp />
                  <Typography style={labelsStyle}>WhatsApp : <div style={labelValueStyle</div></}>Typography>
                </Box>
                <Seperator /> 
                <Box display="flex" alignItems="center">
                  <Icons.cusMerchantTime />
                  <Typography style={labelsStyle}>Time : <div style={labelValueStyle</div></}>Typography>
                </Box>
                <Seperator />
                */}
                <Box display="flex" alignItems="center">
                  <Icons.cusMerchantNotes />
                  <Typography style={labelsStyle}>
                    {words["Note"] + ":"}{" "}
                    <div style={labelValueStyle}> {details?.order_note}</div>
                  </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Map style={{ marginRight: 5 }} />
                  <Typography style={labelsStyle}>
                    {words["Merchant map location"] + ":"}{" "}
                    <div style={labelValueStyle}> {details?.order_note}</div>
                  </Typography>
                  <Link
                    href={details.merchant_map_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "#007bff", fontWeight: "bold" }}
                  >
                    Open in Google Maps
                  </Link>
                </Box>

              </Card>
            </Box>

            <Box mt={3}>
              <Typography style={TitlesStyle}>
                {words["Price Detail"]}
              </Typography>
              <Card
                bordered={true}
                style={{
                  gap: "0px",
                  border: "0.5px solid ",
                  opacity: 1,
                  borderRadius: "15px",
                  marginTop: "15px",
                  padding: "20px",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Icons.cusPriceDelivery />
                  <Typography style={labelsStyle}>
                    {words["Delivery Price"] + ":"}{" "}
                    <div style={labelValueStyle}>
                      {" "}
                      {details?.delivery_price}
                    </div>
                  </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusPriceApp />
                  <Typography style={labelsStyle}>
                    {words["App comission"] + ":"}
                    <div style={labelValueStyle}>
                      {" "}
                      {details?.app_commission}
                    </div>
                  </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusTotalPrices />
                  <Typography style={labelsStyle}>
                    {words["Total Price"] + ":"}{" "}
                    <div style={labelValueStyle}> {details?.total_price}</div>
                  </Typography>
                </Box>



              </Card>

              {/* Rider Rating Section */}
              {details?.rating && (
                <>
                  <Typography style={{ ...TitlesStyle, marginTop: 50 }}>
                    {words["Ratings"]}
                  </Typography>
                  <Box mt={3}>
                    <Typography variant="h5" fontWeight={600}>
                      {words["Rider Rating"]}
                    </Typography>
                    <Card bordered style={{ borderRadius: "15px", marginTop: "15px", padding: "20px" }}>
                      <Box display="flex" alignItems="center">
                        <Star color="warning" />
                        <Typography variant="body1" ml={1}>
                          {words["Rating"]}: <Rate disabled value={details.rating.rating} />
                        </Typography>
                      </Box>
                      <Divider />
                      <Box display="flex" alignItems="center">
                        <Icons.cusMerchantNotes />
                        <Typography variant="body1" ml={1}>
                          {words["Review"]}: <strong>{details.rating.text || "No review provided"}</strong>
                        </Typography>
                      </Box>
                      <Divider />
                      <Box display="flex" alignItems="center">
                        <Icons.cusIconCal />
                        <Typography variant="body1" ml={1}>
                          {words["Rated On"]}: <strong>{getDateString(details.rating.created_at)}</strong>
                        </Typography>
                      </Box>
                    </Card>
                  </Box>
                </>
              )}


            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default OrderDetails;
