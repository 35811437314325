import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import TextEditor from "../../components/TextEditor";
import {
  useGetSettingQuery,
  useCreateSettingMutation,
} from "../../store/services/settingService";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../Api/constants";
import AppLoader from "../../components/Loader/AppLoader";
import { useSelector } from "react-redux";

const Setting = () => {
  const words = useSelector((state) => state.authReducer.words);
  const { data: settingData, isLoading, isSuccess } = useGetSettingQuery();
  const [activeTab, setActiveTab] = useState("setting");
  const [formData, setFormData] = useState({
    company_title: "",
    support_name: "",
    support_email: "",
    company_mobile: "",
    time_zoone: "",
    max_services_distance: "",
    country_code: "",
    primary_color: "",
    secondary_color: "",
    paimary_shadow_color: "",
    otp_system: "",
    // cancel_duration: "",
    company_whatsapp: "",
    company_X: "",
    company_instagram: "",
    referral_bonus: "",
  });
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyShortDesc, setCompanyShortDesc] = useState("");
  const [copyrightDetail, setCopyrightDetail] = useState("");
  const [supportHours, setSupportHours] = useState("");
  const [loginImage, setLoginImage] = useState();
  const [providerLogo, setProviderLogo] = useState();
  const [providerFavicon, setProviderFavicon] = useState();
  const [providerHalfLogo, setProviderHalfLogo] = useState();
  const [adminLogo, setAdminLogo] = useState();
  const [adminFavicon, setAdminFavicon] = useState();
  const [adminHalfLogo, setAdminHalfLogo] = useState();

  const [loading, setLoading] = useState(false);

  const [custPrivacy, setCustPrivacy] = useState("");
  const [custTerms, setCustTerms] = useState("");
  const [storePrivacy, setStorePrivacy] = useState("");
  const [storeTerms, setStoreTerms] = useState("");

  const [editorEditable, setEditorEditable] = useState({
    privacy: false,
    terms: false,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [saveSetting, response] = useCreateSettingMutation();

  console.log("setting save response", response);
  console.log("formData...", formData);

  const handleSubmitSetting = () => {
    const data = new FormData();
    data.append("company_title", formData.company_title);
    data.append("support_name", formData.support_name);
    data.append("support_email", formData.support_email);
    data.append("company_mobile", formData.company_mobile);
    data.append("time_zoone", formData.time_zoone);
    data.append("max_services_distance", formData.max_services_distance);
    data.append("country_code", formData.country_code);
    data.append("primary_color", formData.primary_color);
    data.append("secondary_color", formData.secondary_color);
    data.append("paimary_shadow_color", formData.paimary_shadow_color);
    data.append("otp_system", formData.otp_system);
    data.append("cancel_duration", formData.cancel_duration);
    data.append("cancel_duration", formData.cancel_duration);
    data.append("company_address", companyAddress);
    data.append("company_short_description", companyShortDesc);
    data.append("company_copyright_details", copyrightDetail);
    data.append("company_support_hours", supportHours);
    data.append("login_image", loginImage);
    data.append("provider_logo", providerLogo);
    data.append("provider_favicon", providerFavicon);
    data.append("provider_half_logo", providerHalfLogo);
    data.append("company_logo", adminLogo);
    data.append("company_favicon", adminFavicon);
    data.append("company_half_logo", adminHalfLogo);

    data.append("user_privacy_policy", custPrivacy);
    data.append("user_terms_condition", custTerms);
    data.append("store_terms_condition", storeTerms);
    data.append("store_privacy_policy", storePrivacy);

    data.append("company_whatsapp", formData.company_whatsapp);
    data.append("company_X", formData.company_X);
    data.append("company_instagram", formData.company_instagram);
    data.append("referral_bonus", formData.referral_bonus);

    saveSetting(data);
  };

  useEffect(() => {
    if (response.isSuccess) {
      toast.success("Data Save Successfully");
      window.location.reload();
    }
    if (response.isError) {
      toast.error("Something wrong try again later!");
    }
  }, [response]);

  useEffect(() => {
    if (isSuccess) {
      setFormData(settingData?.data);
      setCompanyAddress(settingData?.data?.company_address);
      setCompanyShortDesc(settingData?.data?.company_short_description);
      setCopyrightDetail(settingData?.data?.company_copyright_details);
      setSupportHours(settingData?.data?.company_support_hours);

      setCustPrivacy(settingData?.data?.user_privacy_policy);
      setCustTerms(settingData?.data?.user_terms_condition);
      setStorePrivacy(settingData?.data?.store_privacy_policy);
      setStoreTerms(settingData?.data?.store_terms_condition);
    }
  }, [settingData]);

  return (
    <>
      <Layout pageTitle={words["Setting"]}>
        <div className="setting-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            {isLoading || response.isLoading ? (
              <Loader />
            ) : (
              <div className="flex flex-col gap-5 mt-6">
                <div className="flex w-full border-b-2 border-b-[#EFDBC4] border-transparent ">
                  <button
                    className={`btn text-themeColor font-semibold rounded ${
                      activeTab === "setting" ? " bg-[#EFDBC4]" : ""
                    }`}
                    onClick={(e) => setActiveTab("setting")}
                  >
                    {words["Setting"]}
                  </button>
                  <button
                    className={`btn text-themeColor font-semibold rounded ${
                      activeTab === "termsPrivacy" ? " bg-[#EFDBC4]" : ""
                    }`}
                    onClick={(e) => setActiveTab("termsPrivacy")}
                  >
                    {words["Terms & Privacy Settings"]}
                  </button>
                </div>
                {activeTab === "setting" ? (
                  <>
                    <div className="setting-grid w-full grid md:grid-cols-1 sm:grid-cols-2 grid-cols-1 gap-4 my-8">
                      <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3">
                        <h1 className="text-black text-base font-medium ">
                          {words["Company Settings"]}
                        </h1>

                        <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />

                        <div className="flex flex-col gap-5">
                          <div className="grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-x-3 gap-y-4">
                            <div className="flex flex-col gap-1">
                              <label className="text-black text-sm font-medium">
                                {words["Company Title"]}
                              </label>
                              <input
                                type="text"
                                name="company_title"
                                value={formData.company_title}
                                onChange={handleInput}
                                className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                              />
                            </div>
                            <div className="flex flex-col gap-1">
                              <label className="text-black text-sm font-medium">
                                {words["Support Name"]}
                              </label>
                              <input
                                type="text"
                                name="support_name"
                                value={formData.support_name}
                                onChange={handleInput}
                                className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                              />
                            </div>

                            <div className="flex flex-col gap-1">
                              <label className="text-black text-sm font-medium">
                                {words["Mobile"]}
                              </label>
                              <input
                                type="text"
                                name="company_mobile"
                                value={formData.company_mobile}
                                onChange={handleInput}
                                className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                              />
                            </div>

                            <div className="flex flex-col gap-1 flex-1">
                              <label className="text-black text-sm font-medium">
                                {words["Country Code"]}
                              </label>
                              <input
                                type="text"
                                name="country_code"
                                value={formData.country_code}
                                onChange={handleInput}
                                className="w-full bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                              />
                            </div>
                          </div>

                          <div className="grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-x-3 gap-y-4">
                            <div className="flex flex-col gap-1">
                              <label className="text-black text-sm font-medium">
                                {words["Support Email"]}
                              </label>
                              <input
                                type="email"
                                name="support_email"
                                value={formData.support_email}
                                onChange={handleInput}
                                className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                              />
                            </div>

                            <div className="flex flex-col gap-1">
                              <label className="text-black text-sm font-medium">
                                {words["Company Whatsapp"]}
                              </label>
                              <input
                                // type="email"
                                name="company_whatsapp"
                                value={formData.company_whatsapp}
                                onChange={handleInput}
                                className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                              />
                            </div>
                            <div className="flex flex-col gap-1">
                              <label className="text-black text-sm font-medium">
                                {words["Company X"]}
                              </label>
                              <input
                                // type="email"
                                name="company_X"
                                value={formData.company_X}
                                onChange={handleInput}
                                className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                              />
                            </div>
                            <div className="flex flex-col gap-1">
                              <label className="text-black text-sm font-medium">
                                {words["Company Instagram"]}
                              </label>
                              <input
                                // type="email"
                                name="company_instagram"
                                value={formData.company_instagram}
                                onChange={handleInput}
                                className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                              />
                            </div>
                            <div className="flex flex-col gap-1">
                              <label className="text-black text-sm font-medium">
                                {words["Referral_bonus"]}
                              </label>
                              <input
                                // type="email"
                                name="referral_bonus"
                                value={formData.referral_bonus}
                                onChange={handleInput}
                                className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                              />
                            </div>
                          </div>

                          {/* <div className="flex flex-col gap-1">
                              <label className="text-black text-sm font-medium">
                                {words['Select Time Zone']}
                              </label>
                              <input
                                type="text"
                                name="time_zoone"
                                value={formData.time_zoone}
                                onChange={handleInput}
                                className="bg-[#FBFBFB] border border-[#D9D9D9] py-1 px-2 focus:border-themeColor rounded-lg anim"
                              />
                            </div> */}

                          {/* <div className="grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-x-3 gap-y-4"></div> */}
                        
                        </div>
                      </div>
                      {/* <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3">
                      <h1 className="text-black text-base font-medium ">
                        Provider Logos
                      </h1>
                      <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                      <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-1">
                          <label className="text-black text-sm font-medium">
                            Logo
                          </label>
                          <div
                            className="img-box flex items-center justify-center h-20 w-full bg-[#FBFBFB] border border-[#D9D9D9] rounded-lg relative cursor-pointer"
                            onClick={() =>
                              document
                                .getElementById("upload_provider_logo")
                                .click()
                            }
                          >
                            {providerLogo ? (
                              <img
                                alt="img"
                                src={URL.createObjectURL(providerLogo)}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            ) : (
                              <img
                                alt="img"
                                src={`${BASE_URL}/${formData.provider_logo}`}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              name="image"
                              id="upload_provider_logo"
                              className="hidden cleanbtn"
                              onChange={(e) => {
                                setProviderLogo(e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <label className="text-black text-sm font-medium">
                            Favicon Logo
                          </label>
                          <div
                            className="img-box flex items-center justify-center h-20 w-full bg-[#FBFBFB] border border-[#D9D9D9] rounded-lg relative cursor-pointer"
                            onClick={() =>
                              document
                                .getElementById("upload_provider_favicon")
                                .click()
                            }
                          >
                            {providerFavicon ? (
                              <img
                                alt="img"
                                src={URL.createObjectURL(providerFavicon)}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            ) : (
                              <img
                                alt="img"
                                src={`${BASE_URL}/${formData.provider_favicon}`}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              name="image"
                              id="upload_provider_favicon"
                              className="hidden cleanbtn"
                              onChange={(e) => {
                                setProviderFavicon(e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <label className="text-black text-sm font-medium">
                            Half Logo
                          </label>
                          <div
                            className="img-box flex items-center justify-center h-20 w-full bg-[#FBFBFB] border border-[#D9D9D9] rounded-lg relative cursor-pointer"
                            onClick={() =>
                              document
                                .getElementById("upload_provider_half_logo")
                                .click()
                            }
                          >
                            {providerHalfLogo ? (
                              <img
                                alt="img"
                                src={URL.createObjectURL(providerHalfLogo)}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            ) : (
                              <img
                                alt="img"
                                src={`${BASE_URL}/${formData.provider_half_logo}`}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              name="image"
                              id="upload_provider_half_logo"
                              className="hidden cleanbtn"
                              onChange={(e) => {
                                setProviderHalfLogo(e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div> 
                    <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3">
                      <h1 className="text-black text-base font-medium ">
                        Admin Logos
                      </h1>
                      <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                      <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-1">
                          <label className="text-black text-sm font-medium">
                            Logo
                          </label>
                          <div
                            className="img-box flex items-center justify-center h-20 w-full bg-[#FBFBFB] border border-[#D9D9D9] rounded-lg relative cursor-pointer"
                            onClick={() =>
                              document
                                .getElementById("upload_admin_logo")
                                .click()
                            }
                          >
                            {adminLogo ? (
                              <img
                                alt="img"
                                src={URL.createObjectURL(adminLogo)}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            ) : (
                              <img
                                alt="img"
                                src={`${BASE_URL}/${formData.company_logo}`}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              name="image"
                              id="upload_admin_logo"
                              className="hidden cleanbtn"
                              onChange={(e) => {
                                setAdminLogo(e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <label className="text-black text-sm font-medium">
                            Favicon Logo
                          </label>
                          <div
                            className="img-box flex items-center justify-center h-20 w-full bg-[#FBFBFB] border border-[#D9D9D9] rounded-lg relative cursor-pointer"
                            onClick={() =>
                              document
                                .getElementById("upload_admin_favicon")
                                .click()
                            }
                          >
                            {adminFavicon ? (
                              <img
                                alt="img"
                                src={URL.createObjectURL(adminFavicon)}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            ) : (
                              <img
                                alt="img"
                                src={`${BASE_URL}/${formData.company_favicon}`}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              name="image"
                              id="upload_admin_favicon"
                              className="hidden cleanbtn"
                              onChange={(e) => {
                                setAdminFavicon(e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <label className="text-black text-sm font-medium">
                            Half Logo
                          </label>
                          <div
                            className="img-box flex items-center justify-center h-20 w-full bg-[#FBFBFB] border border-[#D9D9D9] rounded-lg relative cursor-pointer"
                            onClick={() =>
                              document
                                .getElementById("upload_admin_half_logo")
                                .click()
                            }
                          >
                            {adminHalfLogo ? (
                              <img
                                alt="img"
                                src={URL.createObjectURL(adminHalfLogo)}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            ) : (
                              <img
                                alt="img"
                                src={`${BASE_URL}/${formData.company_half_logo}`}
                                className="h-full w-full overflow-hidden object-contain"
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              name="image"
                              id="upload_admin_half_logo"
                              className="hidden cleanbtn"
                              onChange={(e) => {
                                setAdminHalfLogo(e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>*/}
                    </div>
                    {/* <div className="setting-grid w-full grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
                      <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3 h-96 overflow-hidden">
                        <h1 className="text-black text-base font-medium ">
                          {words['Company Address']}
                        </h1>
                        <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                        <TextEditor
                          text={companyAddress}
                          setText={setCompanyAddress}
                        />
                      </div>
                      <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3 h-96 overflow-hidden">
                        <h1 className="text-black text-base font-medium ">
                          {words['Company Short Description']}
                        </h1>
                        <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                        <TextEditor
                          text={companyShortDesc}
                          setText={setCompanyShortDesc}
                        />
                      </div>
                      <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3 h-96 overflow-hidden">
                        <h1 className="text-black text-base font-medium ">
                          {words['Copyright Details']}
                        </h1>
                        <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                        <TextEditor
                          text={copyrightDetail}
                          setText={setCopyrightDetail}
                        />
                      </div>
                      <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3 h-96 overflow-hidden">
                        <h1 className="text-black text-base font-medium ">
                          {words['Support Hours']}
                        </h1>
                        <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                        <TextEditor
                          text={supportHours}
                          setText={setSupportHours}
                        />
                      </div>
                    </div> */}
                    <div className="flex items-center justify-end">
                      <button
                        className="btn bg-themeColor"
                        onClick={(e) => handleSubmitSetting()}
                      >
                        {words["Save"]}
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="setting-grid w-full grid md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
                    <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3 h-96 overflow-hidden">
                      <h1 className="text-black text-base font-medium ">
                        {words["Customer Terms and Conditions"]}
                      </h1>
                      <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                      <TextEditor
                        initialContent={custTerms}
                        onChange={(text) => setCustTerms(text)}
                      />
                    </div>

                    <div className="shadow-md-custom flex flex-col w-full rounded-2xl p-3 h-96 overflow-hidden">
                      <h1 className="text-black text-base font-medium ">
                        {words["Customer privacy policy"]}
                      </h1>
                      <div className="flex w-full border-b border-[#D9D9D9] mt-5 mb-4" />
                      <TextEditor
                        initialContent={custPrivacy}
                        onChange={(text) => setCustPrivacy(text)}
                      />
                    </div>

                    <div className="flex items-center justify-end">
                      <button
                        className="btn bg-themeColor"
                        onClick={(e) => handleSubmitSetting()}
                      >
                        {words["Save"]}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Setting;
